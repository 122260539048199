import React from 'react';
import styled from 'styled-components';
import SEO from '../components/seo';
import { AuthService } from '../services';
import { navigate } from 'gatsby';
import { LoginLayout } from '../components/Layouts';
import { InOutCardResponsive } from '../components';
import { Text, Button } from '@womcorp/wom-ui-kit';
import { ReactComponent as Ghost } from '../assets/images/ghost.svg';

const NoRouteWrapper = styled.div`
  display: flex;
  flex-direction: column;

  h1,
  h2 {
    text-align: center;
  }

  p {
    text-align: center;
  }
`;

const CustomCardWrapper = styled(InOutCardResponsive)`
  max-width: 470px;
`;

const NoRouteCenteredText = styled(Text)`
  text-align: center;
`;

const GhostImgWrapper = styled.div`
  margin: 30px;
  text-align: center;
`;

const CustomButtonWrapper = styled.div`
  width: 85%;
  margin: 0 auto;
`;

const LinkWrapper = styled.div`
  a {
    color: white;
  }
`;

const handleClick = () => {
  return navigate('/home/');
};

const NotFoundPage = () => (
  <>
    <SEO title="404: Error " />
    <LoginLayout>
      <NoRouteWrapper>
        <CustomCardWrapper>
          <GhostImgWrapper>
            <Ghost />
          </GhostImgWrapper>
          <NoRouteCenteredText>
            Ohh! La página que buscas no existe, ve directo al inicio haciendo click en el botón "IR AL INICIO"
          </NoRouteCenteredText>
          <CustomButtonWrapper>
              <Button onClick={() => handleClick()} variant="primary" size="l" full>
                <LinkWrapper>IR AL INICIO</LinkWrapper>
              </Button>
          </CustomButtonWrapper>
        </CustomCardWrapper>
      </NoRouteWrapper>
    </LoginLayout>
  </>
);

export default NotFoundPage;
